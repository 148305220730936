import React from 'react'
import { graphql } from 'gatsby'
import { object } from 'prop-types'
import dayjs from 'dayjs'

import { ArticlesByMonth, Sitemap } from '@jelly/templates'
import { groupContentByDate } from '@jelly/utils'

const Index = ({ data }) => {
	const groupedArticlesByDate = groupContentByDate(data.articles)

	const firstDate = Object.keys(groupedArticlesByDate)[0]
	const year = dayjs(firstDate).format('YYYY')
	const month = dayjs(firstDate).format('MM')
	const fullMonth = dayjs(firstDate).format('MMMM')

	const meta = {
		title: `${fullMonth} ${year} business.com`,
		description: `Find archived content on business.com from ${fullMonth}, ${year}`,
		robots: 'index, follow',
		canonical: `/sitemap/${year}/${month}/`,
	}
	return (
		<Sitemap meta={meta}>
			<ArticlesByMonth data={groupedArticlesByDate} />
		</Sitemap>
	)
}

Index.propTypes = { data: object, pageContext: object }

export default Index

export const GatsbyQuery = graphql`
	query sitemap($startDate: CDS_Date!, $endDate: CDS_Date!) {
		articles(
			filter: { AND: [{ displayModified: { GTE: $startDate } }, { displayModified: { LT: $endDate } }] }
			sort: { displayModified: DESC }
		) {
			path
			displayModified
			published
			title
		}
	}
`
